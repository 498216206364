'use client';
import React from 'react';

// import Swiper core and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y, Grid as SwiperGrid, Autoplay } from 'swiper/modules';
import { type SwiperOptions } from 'swiper/types';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import sidekick from '@last-rev/contentful-sidekick-util';

import type { CarouselProps, CarouselOwnerState } from './Carousel.types';
import Background from '../Background';
import Grid from '../Grid';
import ContentModule from '../ContentModule';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/grid';

import dynamic from 'next/dynamic';

const Carousel = (props: CarouselProps) => {
  const ownerState = { ...props };
  const [loaded, setLoaded] = React.useState(false);

  const {
    backgroundImage,
    backgroundColor,
    isCarouselDesktop,
    isCarouselTablet,
    isCarouselMobile,
    carouselAutoPlay,
    disclaimerText,
    items,
    actions,
    variant,
    itemsVariant,
    sidekickLookup,
    introText,
    itemsPerRow = 3,
    numItems = props?.items?.length ?? 3,
    anchorName
  } = props;
  const theme = useTheme();

  const swiperBreakpoints: { [width: number]: SwiperOptions } = {};
  let spaceBetween = 24;
  let spaceBetweenMobile = 24;
  let slidesPerView = Math.min(itemsPerRow, numItems);
  switch (itemsVariant) {
    case 'logo':
      spaceBetween = 80;
      spaceBetweenMobile = 8;
      break;
  }

  // Can change the slidePerView below if you don't want to show a partial for the next slide
  swiperBreakpoints['1'] = {
    grid: {
      rows: isCarouselMobile ? 1 : numItems,
      fill: 'row'
    },
    slidesPerView: Math.max(1, itemsPerRow > 1 ? slidesPerView - 3 : 1),
    spaceBetween: spaceBetweenMobile
  };

  swiperBreakpoints[theme.breakpoints.values.xs] = {
    grid: {
      rows: isCarouselMobile ? 1 : numItems,
      fill: 'row'
    },
    slidesPerView: Math.max(1, itemsPerRow > 1 ? slidesPerView - 3 : 1),
    spaceBetween: spaceBetweenMobile
  };

  swiperBreakpoints[theme.breakpoints.values.sm] = {
    grid: {
      rows: isCarouselTablet ? 1 : numItems,
      fill: 'row'
    },
    slidesPerView: Math.max(1, itemsPerRow > 1 ? slidesPerView - 2 : 1),
    spaceBetween: spaceBetweenMobile
  };

  swiperBreakpoints[theme.breakpoints.values.md] = {
    grid: {
      rows: isCarouselDesktop ? 1 : numItems,
      fill: 'row'
    },
    slidesPerView: Math.max(1, itemsPerRow > 1 ? slidesPerView : 1),
    spaceBetween
  };

  // swiperBreakpoints[theme.breakpoints.values.lg] = {
  //   grid: {
  //     rows: isCarouselDesktop ? 1 : numItems,
  //     fill: 'row'
  //   },
  //   // slidesPerView: itemsPerRow > 2 ? slidesPerView : slidesPerView,
  //   slidesPerView,
  //   spaceBetween
  // };

  if (itemsPerRow === 4) {
    swiperBreakpoints[theme.breakpoints.values.sm] = {
      grid: {
        rows: isCarouselDesktop ? 1 : numItems,
        fill: 'row'
      },
      slidesPerView: 4,
      spaceBetween
    };

    swiperBreakpoints[theme.breakpoints.values.lg] = {
      grid: {
        rows: isCarouselDesktop ? 1 : numItems,
        fill: 'row'
      },
      slidesPerView: slidesPerView,
      spaceBetween
    };
  } else if (itemsPerRow >= 5) {
    swiperBreakpoints[theme.breakpoints.values.xl] = {
      grid: {
        rows: isCarouselDesktop ? 1 : numItems,
        fill: 'row'
      },
      slidesPerView: slidesPerView,
      spaceBetween
    };
  }

  return (
    <>
      {!loaded ? (
        <ContentModule
          {...props}
          __typename="Collection"
          items={slidesPerView === 1 ? [items?.[0]] : items}
          variant={variant?.replace('Carousel', '')}
          // itemsVariant={'carouselHeroItem'}
          // items={items?.map((item: any) => ({ ...item, variant: 'carouselHeroItem' }))}
          sx={
            loaded
              ? {
                  opacity: 0,
                  top: 0,
                  position: 'absolute',
                  pointerEvents: 'none',
                  visibility: 'hidden'
                }
              : {}
          }
        />
      ) : null}
      <Root
        id={anchorName}
        ownerState={ownerState}
        {...sidekick(sidekickLookup)}
        data-testid={`Carousel-${variant}`}
        sx={
          loaded
            ? { opacity: 1 }
            : {
                opacity: 0,
                position: 'absolute',
                top: 0
              }
        }>
        <CarouselBackground
          background={backgroundImage}
          backgroundColor={backgroundColor}
          testId="Carousel-background"
        />
        {introText && (
          <IntroTextGrid ownerState={ownerState}>
            <IntroText
              ownerState={ownerState}
              {...sidekick(sidekickLookup, 'introText')}
              {...introText}
              textVariant="introText"
            />
          </IntroTextGrid>
        )}

        <ContentGrid ownerState={ownerState}>
          <SwiperWrap ownerState={ownerState}>
            <SwiperInnerWrap ownerState={ownerState}>
              <Swiper
                onSwiper={(swiper) => {
                  setTimeout(() => {
                    setLoaded(true);
                    swiper.slideTo(0, 0);
                  }, 500);
                }}
                initialSlide={1}
                rewind={true}
                breakpointsBase="container"
                cssMode={true}
                modules={[Navigation, SwiperGrid, Pagination, A11y, Autoplay]}
                spaceBetween={24}
                breakpoints={swiperBreakpoints}
                pagination={{ clickable: true }}
                navigation
                autoplay={
                  carouselAutoPlay
                    ? { disableOnInteraction: false, pauseOnMouseEnter: true }
                    : false
                }
                // scrollbar={{ draggable: true }}
                // onSwiper={(swiper) => console.log(swiper)}
                // onSlideChange={() => console.log('slide change')}
              >
                {items?.map((item, index) => (
                  <SwiperSlide key={item?.id}>
                    <Item
                      // backgroundColor={backgroundColor}
                      ownerState={ownerState}
                      {...item}
                      variant={itemsVariant ?? item?.variant}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </SwiperInnerWrap>
          </SwiperWrap>
          {!!actions?.length && (
            <ActionsWrap
              {...sidekick(sidekickLookup, 'actions')}
              data-testid="Collection-actions"
              ownerState={ownerState}>
              {actions.map((action) => (
                <Action ownerState={ownerState} key={action?.id} {...action} />
              ))}
            </ActionsWrap>
          )}
          {!!disclaimerText && (
            <DisclaimerText
              {...sidekick(sidekickLookup, 'disclaimerText')}
              body={disclaimerText}
              __typename="RichText"
              data-testid="Collection-disclaimerText"
              variant="body2"
              ownerState={ownerState}
            />
          )}
        </ContentGrid>
      </Root>
    </>
  );
};

const Root = styled(Box, {
  name: 'Carousel',
  slot: 'Root',
  overridesResolver: ({ ownerState }, styles) => [styles.root, styles[`${ownerState?.variant}`]]
})<{ ownerState: CarouselOwnerState }>``;

const CarouselBackground = styled(Background, {
  name: 'Carousel',
  slot: 'Background',
  overridesResolver: (_, styles) => [styles.background]
})<{}>``;

const ContentGrid = styled(Grid, {
  name: 'Carousel',
  slot: 'ContentGrid',
  overridesResolver: (_, styles) => [styles.contentGrid]
})<{ ownerState: CarouselOwnerState }>``;

const IntroTextGrid = styled(Grid, {
  name: 'Carousel',
  slot: 'IntroTextGrid',
  overridesResolver: (_, styles) => [styles.introTextGrid]
})<{ ownerState: CarouselOwnerState }>``;

const IntroText = styled(ContentModule, {
  name: 'Carousel',
  slot: 'IntroText',
  overridesResolver: (_, styles) => [styles.introText]
})<{ ownerState: CarouselOwnerState }>``;

const SwiperWrap = styled(Box, {
  name: 'Carousel',
  slot: 'SwiperWrap',
  overridesResolver: (_, styles) => [styles.swiperWrap]
})<{ ownerState: CarouselOwnerState }>``;

const SwiperInnerWrap = styled(Box, {
  name: 'Carousel',
  slot: 'SwiperInnerWrap',
  overridesResolver: (_, styles) => [styles.swiperInnerWrap]
})<{ ownerState: CarouselOwnerState }>``;

const Item = styled(ContentModule, {
  name: 'Carousel',
  slot: 'Item',
  overridesResolver: (_, styles) => [styles.item]
})<{ ownerState: CarouselOwnerState }>``;
const ActionsWrap = styled(Box, {
  name: 'Carousel',
  slot: 'ActionsWrap',
  overridesResolver: (_, styles) => [styles.actionsWrap]
})<{ ownerState: CarouselOwnerState }>``;

const Action = styled(ContentModule, {
  name: 'Carousel',
  slot: 'Action',
  overridesResolver: (_, styles) => [styles.action]
})<{ ownerState: CarouselOwnerState }>``;

const DisclaimerText = styled(ContentModule, {
  name: 'Carousel',
  slot: 'DisclaimerText',
  overridesResolver: (_, styles) => [styles.disclaimerText]
})<{ ownerState: CarouselOwnerState }>``;
export default Carousel;
